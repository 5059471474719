import React from "react"

const TodoPage = () => {

  return (
    <ul className="container py-24 list-disc ">

      <li>- query for header</li>
      <li>- query for footer</li>
      <li>integrate with end point</li>
      <li>create staging git branch</li>
      <li>- 404 page</li>
      <li>home page - stories - make them clikable </li>
      <li>article page - more articles section - add swiper</li>
      <li>search</li>
      <li>icons front page top - create block in the cms - then integrate</li>
      <li>integrate gatsby image whenever possible</li>
      <li>mobile </li>
      <li>privacy page </li>
      <li>move text styles to tailwind config</li>
      <li>move all components to libarary</li>
    </ul>
  )
}

export default TodoPage